import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import { auth, googleProvider } from "../../authentication/Firebase.js";

const AdminLogin = () => {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const idToken = await user.getIdToken();

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sessionLogin`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Origin": window.location.origin,
        },
        body: JSON.stringify({ idToken }),
      });

      if (response.ok) {
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/admin`;
      } else {
        const errorData = await response.json();
        setMessage(t('pages.adminLogin.errors.loginFailed', { error: errorData.error }));
      }
    } catch (error) {
      console.error('Popup error:', error);
      setMessage(t('pages.adminLogin.errors.generic', { message: error.message }));
    }
  };

  return (
    <div data-testid="admin-login-container">
      <h2>{t('pages.adminLogin.title')}</h2>
      <p>{t('pages.adminLogin.description')}</p>
      <button 
        onClick={handleGoogleLogin}
        data-testid="google-login-button"
      >
        {t('pages.adminLogin.actions.signInWithGoogle')}
      </button>
      {message && <p data-testid="error-message">{message}</p>}
    </div>
  );
};

export default AdminLogin;
