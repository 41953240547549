import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import LoginPage from '../../pages/LoginPage/LoginPage';
import { auth } from '../../authentication/Firebase';
import useAuth from '../../hooks/useAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/theme.css';
import '../../styles/styles.css';
import './Header.css';
import { FaSearch, FaTimes, FaUserCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import MedlaLogo from '../../assets/medla_logo.svg';
import MedlaLogoMobile from '../../icon.svg';

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [userProfileId, setUserProfileId] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [modalType, setModalType] = useState('login');
  const { t } = useTranslation();

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };
  
  const handleCloseModal = () => setShowModal(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Clear user-related states immediately
      setUserProfileId(null);
      setProfileImageUrl(null);
      // Force a page reload to clear all states
      window.location.reload();
      // Note: The navigate('/') call is not needed since we're reloading the page
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search${searchQuery.trim() ? `?query=${encodeURIComponent(searchQuery)}` : ''}`);
    setSearchQuery(''); // Clear the search input after searching
  };

  const handleMobileSearch = (e) => {
    e.preventDefault();
    navigate(`/search${searchQuery.trim() ? `?query=${encodeURIComponent(searchQuery)}` : ''}`);
    setSearchQuery('');
    setShowMobileSearch(false);
  };

  const [showMobileSearch, setShowMobileSearch] = useState(false);

  useEffect(() => {
    const fetchUserProfileId = async () => {
      if (user && auth.currentUser) {
        try {
          const idToken = await auth.currentUser.getIdToken(true);
          
          // Updated endpoint to match ProfilePage.js
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-profile/${user.uid}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${idToken}`,
            }
          });
          
          if (response.ok) {
            const data = await response.json();
            setUserProfileId(data.uid || user.uid); // Fallback to user.uid if needed
            setProfileImageUrl(data.PublicData_y?.profileImageUrl); // Add this line to store the profile image URL
          } else {
            setUserProfileId(user.uid);
          }
        } catch (error) {
          console.error('Error fetching user profile ID:', error);
          setUserProfileId(user.uid);
        }
      }
    };

    fetchUserProfileId();
  }, [user]);

  useEffect(() => {
    if (user) {
      handleCloseModal();
    }
  }, [user]);

  return (
    <header className="header sticky-header">
      <nav className="navbar navbar-light">
        <div className="header-left-group">
          <button 
            className="mobile-search-trigger"
            onClick={() => setShowMobileSearch(true)}
            aria-label={t('components.Header.search.ariaLabel.open')}
            data-testid="mobile-search-trigger"
          >
            <FaSearch className="search-icon" />
          </button>
        </div>

        <Link to="/" className="navbar-brand">
          <img
            src={MedlaLogo}
            alt={t('components.Header.accessibility.logo')}
            className="logo"
          />
          <img
            src={MedlaLogoMobile}
            alt={t('components.Header.accessibility.logo')}
            className="logo-mobile"
          />
        </Link>

        <div className="header-center">
          <form onSubmit={handleSearch} className="search-form">
            <div className="search-input-wrapper">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t('components.Header.search.placeholder')}
                className="search-input"
              />
              <div className="ai-indicator">AI</div>
              <button type="submit" className="search-button">
                <FaSearch className="search-icon" />
              </button>
            </div>
          </form>
        </div>

        <div className="header-right">
          {user ? (
            <div className="user-menu">
              <div className="avatar">
                {profileImageUrl ? (
                  <img src={profileImageUrl} alt={t('components.Header.accessibility.profileImage')} className="avatar-img" />
                ) : user.photoURL ? (
                  <img src={user.photoURL} alt={t('components.Header.accessibility.profileImage')} className="avatar-img" />
                ) : (
                  <div className="avatar-placeholder">
                    {user.email?.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
              <div className="dropdown-menu">
                <Link to={`/profile/${userProfileId || user.uid}`} className="menu-item">
                  {t('components.Header.auth.profile')}
                </Link>
                <div className="menu-divider"></div>
                <button className="menu-item" onClick={handleLogout}>
                  {t('components.Header.auth.logout')}
                </button>
              </div>
            </div>
          ) : (
            <>
              {/* Desktop auth buttons */}
              <div className="desktop-auth">
                <button 
                  className="btn btn-primary" 
                  onClick={() => handleShowModal('login')}
                >
                  {t('components.Header.auth.login')}
                </button>
                <button 
                  className="btn btn-secondary" 
                  onClick={() => handleShowModal('register')}
                >
                  {t('components.Header.auth.register')}
                </button>
              </div>
              
              {/* Mobile auth button */}
              <button 
                className="mobile-auth-trigger"
                onClick={() => handleShowModal('login')}
                aria-label={t('components.Header.auth.login')}
              >
                <FaUserCircle className="auth-icon" />
              </button>
            </>
          )}
        </div>
      </nav>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t(`components.Header.modal.${modalType}Title`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginPage 
            modalType={modalType} 
            onModalTypeChange={setModalType} 
          />
        </Modal.Body>
      </Modal>

      {showMobileSearch && (
        <div className="mobile-search-modal">
          <div className="mobile-search-header">
            <button 
              className="mobile-search-close"
              onClick={() => setShowMobileSearch(false)}
              aria-label={t('components.Header.search.ariaLabel.close')}
              data-testid="mobile-search-close"
            >
              <FaTimes />
            </button>
            <form onSubmit={handleMobileSearch} className="search-form" style={{margin: 0}}>
              <div className="search-input-wrapper">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={t('components.Header.search.mobilePlaceholder')}
                  className="search-input"
                  autoFocus
                />
                <div className="ai-indicator">
                  <span>AI</span>
                </div>
                <button type="submit" className="search-button">
                  <FaSearch className="search-icon" />
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
