import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './config/languageConfig';
import './utils/devTools';

// Initialize Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [
        "localhost", 
        /^https:\/\/preview\.medla\.app/,
        /^https:\/\/medla\.app/,
        process.env.REACT_APP_BACKEND_URL
      ],
      // Disable tracing for development API calls if needed
      shouldCreateSpanForRequest: (url) => {
        return process.env.REACT_APP_ENV === 'prod' || !url.includes('localhost');
      }
    }),
    Sentry.replayIntegration(),
  ],
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      language: document.documentElement.lang || navigator.language || 'en'
    };
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_ENV === 'prod' ? 0.2 : 1.0,
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_ENV === 'prod' ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
  // Error Monitoring
  enabled: true,
  environment: process.env.REACT_APP_ENV || 'development',
  debug: process.env.REACT_APP_ENV !== 'prod',
  initialScope: {
    tags: {
      environment: process.env.REACT_APP_ENV || 'development'
    }
  },
  autoSessionTracking: true,
  attachStacktrace: true,
});

// Add global error handler
window.onerror = function(message, source, lineno, colno, error) {
  Sentry.captureException(error || message);
  return false;
};

// Add unhandled promise rejection handler
window.onunhandledrejection = function(event) {
  Sentry.captureException(event.reason);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
