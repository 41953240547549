export default {
  pages: {
    search: {
      title: 'Sök',
      navigation: {
        previous: 'Föregående',
        next: 'Nästa',
        pageInfo: 'Sida {{page}} av {{totalPages}}'
      },
      views: {
        showList: 'Visa lista',
        showMap: 'Visa karta'
      },
      errors: {
        fetchCompanies: 'Kunde inte hämta företag'
      }
    },
    home: {
      hero: {
        title: 'Gör lokala affärer.',
        subtitle: 'Hitta lokala företag i gröna industriprojekt.',
        cta: 'Sök företag'
      },
      profileCompletion: {
        title: 'Förbättra profil',
        percentComplete: '{{percent}}% klart',
        groups: {
          profile: 'Profil',
          contact: 'Kontakt',
          company: 'Företag'
        }
      },
      profileChecks: {
        profileImageUrl: 'Profilbild',
        title: 'Företagsnamn',
        address: 'Adress',
        companyContact: 'Kontaktperson',
        contactNumber: 'Telefon',
        contactEmail: 'E-post',
        companyNumber: 'Org.nummer',
        description: 'Beskrivning'
      },
      projects: {
        title: 'Projekt',
        velinga: {
          name: 'Velinga',
          location: 'Tidaholm'
        },
        tomasliden: {
          name: 'Tomasliden',
          location: 'Norsjö'
        }
      },
      howItWorks: {
        title: 'Hur det fungerar',
        steps: {
          register: {
            title: '1. Registrera ditt företag',
            description: 'Det är gratis och du förbinder dig inte till något när du registrerar dig.'
          },
          describe: {
            title: '2. Beskriv företaget',
            description: 'Beskriv era tjänster för att få ut det mesta av plattformen. Väl beskrivna företag genererar mer trafik och affärer.'
          },
          complete: {
            title: '3. Klart',
            description: 'Ditt företag är nu synligt på Medla, lycka till med affärerna!'
          }
        }
      },
      welcomeModal: {
        title: 'Välkommen till Medla!',
        description: 'Ett sista steg... Lägg till en kort beskrivning av din verksamhet och dina kontaktuppgifter. Tips: Detta gör det enklare för kunder att hitta och kontakta dig!',
        actions: {
          close: 'Stäng',
          completeProfile: 'Färdigställ din profil'
        }
      }
    },
    login: {
      title: {
        login: 'Logga in',
        register: 'Registrera dig',
      },
      subtitle: {
        login: 'Logga in på Medla',
        register: 'Skapa ett konto'
      },
      form: {
        email: {
          label: 'E-postadress',
          placeholder: 'namn@exempel.se'
        },
        button: {
          login: 'Skicka inloggningslänk',
          register: 'Skapa konto',
          loading: {
            login: 'Skickar länk...',
            register: 'Skapar konto...'
          }
        },
        toggle: {
          login: 'Har du inget konto? ',
          register: 'Har du redan ett konto? ',
          linkText: {
            login: 'Registrera dig',
            register: 'Logga in'
          }
        }
      },
      terms: {
        login: {
          text: 'Vi skickar en säker inloggningslänk till din e-post. Genom att fortsätta godkänner du våra ',
          linkText: 'villkor'
        },
        register: {
          text: 'Vi skickar en verifieringslänk till din e-post. Genom att fortsätta godkänner du våra ',
          linkText: 'villkor'
        },
        modal: {
          title: 'Användarvillkor',
          close: 'Stäng'
        }
      },
      success: {
        title: {
          login: 'Väntar på att du ska logga in',
          register: 'Väntar på att du ska aktivera ditt konto'
        },
        subtitle: {
          login: 'Gå till din e-post och klicka p länken för att logga in',
          register: 'Gå till din e-post och klicka på länken för att aktivera ditt konto'
        },
        steps: {
          title: 'Vad händer nu?',
          login: [
            'Öppna din e-post',
            'Klicka på länken i mailet',
            'Du loggas in automatiskt'
          ],
          register: [
            'Öppna din e-post',
            'Klicka på länken för att aktivera',
            'Du loggas in automatiskt'
          ]
        },
        noEmail: {
          message: 'Hittar du inte mailet? Kolla i skräpposten eller',
          retry: 'försök igen'
        }
      },
      errors: {
        generic: 'Ett fel uppstod: {{error}}'
      },
      messages: {
        linkSent: 'En inloggningslänk har skickats till din e-postadress. Vänligen kontrollera din inkorg.'
      }
    },
  

    
    finishregister: {
      verifying: {
        title: 'Medla verifierar din mail'
      },
      verified: {
        title: 'Verifiering slutförd!',
        message: 'Du kan nu gå tillbaka till fönstret där du loggade in.',
        continueMessage: 'Eller klicka',
        here: 'här',
        continueInWindow: 'för att fortsätta i detta fönster.'
      },
      error: {
        title: 'Ett fel uppstod',
        message: 'Det gick inte att verifiera din e-post. Vänligen försök igen.',
        backToLogin: 'Gå tillbaka till inloggningssidan'
      }
    },
    adminLogin: {
      title: 'Stig Dashboard Inloggning',
      description: 'Vänligen logga in med ditt @peerdigital.se Google-konto',
      actions: {
        signInWithGoogle: 'Logga in med Google'
      },
      errors: {
        loginFailed: 'Inloggning misslyckades: {{error}}',
        generic: 'Fel: {{message}}'
      }
    },
    profile: {
      loading: 'Laddar...',
      alerts: {
        pendingReview: 'Din profil granskas. Den kommer att publiceras inom 24 timmar.',
        unavailable: 'Denna profil är inte tillgänglig',
        updateSuccess: 'Profilen har uppdaterats!',
        updateError: 'Ett fel uppstod vid uppdatering: {{error}}',
        uploadError: 'Det gick inte att ladda upp bilden'
      },
      actions: {
        edit: 'Redigera profil',
        cancelEdit: 'Avbryt redigering',
        save: 'Spara ändringar',
        saving: 'Sparar...',
        cancel: 'Avbryt'
      },
      sections: {
        about: {
          title: 'Om'
        },
        contact: {
          showEmail: 'Visa e-post',
          showPhone: 'Visa telefon',
          email: 'E-post',
          phone: 'Telefon'
        }
      },
      buttons: {
        showEmail: 'Visa e-post',
        showPhone: 'Visa telefon'
      }
    },
    faq: {
      title: 'Vanliga frågor',
      questions: {
        q1: 'Vilka använder Medla?',
        q2: 'Vilka projekt används Medla för?',
        q3: 'Är det lätt att komma igång?',
        q4: 'Hur kan Medla vara gratis för småföretagare?',
        q5: 'Kostar Medla något?',
        q7: 'Vilka står bakom Medla?',
        q8: 'Hur rapporterar jag ett företag på Medla?'
      },
      answers: {
        a1: 'Kommuner som vill visa och göra sitt näringsliv mer tillgängligt, projektörer som vill lyfta vinsterna och förankra sina etableringar, byggentreprenad som behöver varor och tjänster i projekt och lokala verksamheter som vill leverera till projekten.',
        a2: 'Hållbara energi- och industriprojekt i alla faser (vindparker, solparker, vätgasanläggningar, batterifabriker, projekt för grönt stål och många fler)',
        a3: 'Det tar bara ett par minuter, registrera ditt företag och bli synligt för projektägarna idag.',
        a4: 'Medlas huvudsakliga affärsmodell ligger hos kommuner och projektägare.',
        a5: 'Småföretagare kommer alltid kunna använda Medla gratis.',
        a7: 'Medla drivs av Peer Digital Sweden AB. Bolaget är startat av tidigare projektledare och utvecklare från Umeå Universitet.',
        a8: 'Om du vill rapportera ett företag på Medla, vänligen kontakta oss på '
      },
      email: {
        support: 'support@medla.app'
      }
    },
    help: {
      title: 'Hej!',
      description: 'Hur kan vi hjälpa dig?',
      content: {
        text3: 'Kontakta oss gärna via e-post om du behöver support eller har frågor.',
        contactButton: 'Kontakta support'
      }
    }
  },
  components: {
    ProfileEditForm: {
      sections: {
        companyDetails: 'Företagsuppgifter',
        about: 'Om detta företag',
        services: 'Tjänster'
      },
      fields: {
        image: {
          label: 'Företagsbild'
        },
        company: {
          name: 'Företagsnamn',
          description: 'Företagsbeskrivning',
          address: 'Adress',
          orgNumber: 'Organisationsnummer'
        },
        contact: {
          person: 'Kontaktperson',
          phone: 'Telefonnummer',
          email: 'E-postadress'
        },
        categories: {
          label: 'Branscher',
          options: {
            transport: 'Transport',
            other: 'Övrigt'
          }
        },
        services: {
          title: 'Tjänst {{number}} - Rubrik',
          description: 'Tjänst {{number}} - Beskrivning',
          placeholders: {
            title: 'Ange tjänstens namn',
            description: 'Beskriv tjänsten...'
          }
        }
      },
      actions: {
        addService: 'Lägg till tjänst',
        removeService: 'Ta bort tjänst {{number}}'
      },
      validation: {
        serviceRequired: 'Både rubrik och beskrivning krävs för varje tjänst',
        confirmDelete: 'Är du säker på att du vill ta bort denna tjänst?'
      },
      status: {
        loading: 'Laddar...',
        error: 'Ett fel uppstod'
      }
    },
    CategorySection: {
      title: 'Branscher',
      noCategories: 'Inga branscher angivna.',
      selectPlaceholder: 'Välj branscher...',
      examples: 'Exempel:',
      categories: {
        bygg_och_konstruktion: {
          label: 'Bygg & Konstruktion',
          examples: 'Anläggning, Betong, Bygg, Sprängning, Svets och metall, Projektering, Tillverkning, Skogsmaskintjänster'
        },
        boende: {
          label: 'Boende',
          examples: 'Hotell, Vandrarhem, Stugor, Camping, Lägenheter, Bed & Breakfast'
        },
        restaurang_och_catering: {
          label: 'Restaurang & Catering',
          examples: 'Restaurang, Catering, Matlagning, Servering'
        },
        teknik_och_underhall: {
          label: 'Teknik & Underhåll',
          examples: 'El, larm och fiber, IT och telecom, Maskinreparation, Drift och Underhåll'
        },
        transport_och_fordon: {
          label: 'Transport & Fordon',
          examples: 'Fordon och däck, Transport'
        },
        affarstjanster_och_support: {
          label: 'Affärstjänster & Support',
          examples: 'Bemanning, Utbildning, Media och PR, Servicetjänster, Konferenser'
        }
      }
    },

    LocationMap: {
      noLocation: 'Ingen adressinformation tillgänglig',
      defaultLocation: {
        city: 'Stockholm',
        country: 'Sverige'
      }
    },
    OffersDisplay: {
      title: 'Tjänster',
      actions: {
        addOffer: 'Lägg till tjänst',
        removeOffer: 'Ta bort tjänst'
      },
      messages: {
        noOffers: 'Inga tjänster tillgängliga.',
        confirmDelete: 'Är du säker på att du vill ta bort denna tjänst?'
      }
    },
    ProfileHeader: {
      title: {
        default: 'Ny användare'
      },
      contact: {
        default: 'Kontaktperson ej angiven'
      },
      actions: {
        edit: 'Redigera profil',
        cancel: 'Avbryt redigering'
      },
      imageAlt: 'Profilbild'
    },
    CompanyCard: {
      defaults: {
        title: 'Namnlöst företag',
        address: 'Ej angiven',
      },
      accessibility: {
        profileImage: 'Företagslogotyp',
        placeholderImage: 'Företagsikon'
      },
      categories: {
        bygg_och_konstruktion: 'Bygg & Konstruktion',
        teknik_och_underhall: 'Teknik & Underhåll',
        transport_och_fordon: 'Transport & Fordon',
        affarstjanster_och_support: 'Affärstjänster & Support',
        restaurang_och_catering: 'Restaurang & Catering',
        boende: 'Boende'
      },
      subcategories: {
        anlaggning: 'Anläggning',
        betong: 'Betong',
        bygg: 'Bygg',
        sprangning: 'Sprängning',
        svets_och_metall: 'Svets och metall',
        projektering: 'Projektering',
        tillverkning: 'Tillverkning',
        skogsmaskintjanster: 'Skogsmaskintjänster',
        el_larm_fiber: 'El, larm och fiber',
        it_telecom: 'IT och telecom',
        maskinreparation: 'Maskinreparation',
        drift_underhall: 'Drift och Underhåll',
        fordon_dack: 'Fordon och däck',
        transport: 'Transport',
        bemanning: 'Bemanning',
        utbildning: 'Utbildning',
        media_pr: 'Media och PR',
        servicetjanster: 'Servicetjänster',
        kost_logi: 'Kost och logi',
        ovrigt: 'Övrigt'
      }
    },
    CompanyList: {
      title: 'Lokala företag',
      actions: {
        export: 'Exportera företagslista till Excel',
        showMore: 'Visa fler',
        showLess: 'Visa färre'
      },
      export: {
        columns: {
          name: 'Namn',
          title: 'Titel',
          email: 'E-post',
          phone: 'Telefon',
          address: 'Adress',
          category: 'Bransch',
          offerHeading: 'Tjänstrubrik',
          offer: 'Tjänst'
        },
        filename: 'foretag.xlsx',
        sheetName: 'Företag'
      }
    },
    Footer: {
      copyright: '© {{year}} Peer Digital Sweden AB'
    },
    Header: {
      search: {
        placeholder: 'Beskriv vad du söker',
        mobilePlaceholder: 'Beskriv vad du söker',
        ariaLabel: {
          open: 'Öppna sök',
          close: 'Stäng sök'
        }
      },
      auth: {
        login: 'Logga in',
        register: 'Registrera',
        logout: 'Logga ut',
        profile: 'Gå till min profil'
      },
      modal: {
        loginTitle: 'Logga in',
        registerTitle: 'Registrera dig'
      },
      accessibility: {
        logo: 'Medla Logotyp',
        profileImage: 'Profilbild'
      }
    },
    PopularBranches: {
      title: 'Populära branscher',
      actions: {
        seeAll: 'Se alla branscher'
      },
      branches: {
        it: 'IT',
        healthcare: 'Sjukvård',
        education: 'Utbildning'
        // Add more branches as needed
      },
      // Add to your existing translations

    },
    ProjectHeader: {
      defaults: {
        coordinates: {
          lat: 64.914148,
          lng: 19.483077
        }
      },
      errors: {
        noCoordinates: 'Inga giltiga koordinater tillgängliga, använder standardvärden'
      },
      map: {
        zoom: 9.9
      },
      api: {
        fetchError: 'Kunde inte hämta företag'
      }
    },
    ProjectInfo: {
      title: 'Om projektet',
      defaults: {
        name: 'Projektnamn saknas',
        description: 'Projektbeskrivning saknas'
      }
    }
  },
  cookieBanner: {
    message: 'Vi använder cookies för förbättra din upplevelse och för att förbättra tjänsten.',
    accept: 'Acceptera',
    learnMore: 'Läs mer'
  },
  cookiePolicy: {
    title: 'Cookie Policy',
    description: 'Vi använder följande cookies och lokal lagring:',
    types: {
      essential: {
        title: 'Nödvändiga',
        description: 'För att hålla dig inloggad'
      },
      analytics: {
        title: 'Analys',
        description: 'För att räkna hur många gånger profiler och deras kontaktinformation visas'
      }
    },
    currentStatus: 'Nuvarande status',
    statusAccepted: 'Du har accepterat cookies',
    statusDeclined: 'Du har inte accepterat cookies',
    giveConsent: 'Acceptera cookies',
    withdrawConsent: 'Återkalla samtycke'
  }
}