import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import CategorySection from './CategorySection';
import { useTranslation } from 'react-i18next';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const ProfileEditForm = ({
  userProfile,
  setUserProfile,
  publicData,
  setPublicData,
  handlePublicDataChange,
  handleSave,
  handleImageUpload,
  uploading,
  message,
  handleCategoryChange
}) => {
  const { t } = useTranslation();
  
  const [visibleOffers, setVisibleOffers] = useState(() => {
    let count = 0;
    for (let i = 1; i <= 5; i++) {
      if (publicData[`offer${i}`] || publicData[`offerHeading${i}`]) {
        count = i;
      }
    }
    return Math.max(1, count);
  });

  const isOfferComplete = (offerNum) => {
    return publicData[`offerHeading${offerNum}`]?.trim() && 
           publicData[`offer${offerNum}`]?.trim();
  };

  const areAllOffersValid = () => {
    for (let i = 1; i <= visibleOffers; i++) {
      const hasHeading = !!publicData[`offerHeading${i}`]?.trim();
      const hasDescription = !!publicData[`offer${i}`]?.trim();
      if ((hasHeading && !hasDescription) || (!hasHeading && hasDescription)) {
        return false;
      }
    }
    return true;
  };

  const addNewOffer = () => {
    if (visibleOffers < 5 && isOfferComplete(visibleOffers)) {
      setVisibleOffers(visibleOffers + 1);
    }
  };

  const geocoderContainerRef = useRef(null);
  const geocoderRef = useRef(null);

  useEffect(() => {
    if (!geocoderRef.current && geocoderContainerRef.current) {
      try {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          types: 'address',
          countries: 'se',
          language: 'sv',
          placeholder: t('components.ProfileEditForm.fields.company.address'),
          marker: false,
        });

        geocoderRef.current = geocoder;

        geocoder.addTo(geocoderContainerRef.current);

        geocoder.on('result', (e) => {
          const { result } = e;
          const address = result.place_name;
          const [longitude, latitude] = result.center;

          handlePublicDataChange({
            target: {
              name: 'location',
              value: {
                address,
                longitude,
                latitude
              }
            }
          });
        });

        if (publicData?.location?.address) {
          geocoder.setInput(publicData.location.address);
        }

        geocoder.on('error', () => {
          console.error('Geocoder error');
        });

      } catch (error) {
        console.error('Error initializing geocoder:', error);
      }
    }

    return () => {
      if (geocoderRef.current) {
        geocoderRef.current.onRemove();
        geocoderRef.current = null;
      }
    };
  }, [publicData?.location?.address, handlePublicDataChange, t]);

  return (
    <Form>
      {message && (
        <Alert variant={message.type} className="mt-3">
          {message.text}
        </Alert>
      )}

      <h2 className="form-section-header mb-4">{t('components.ProfileEditForm.sections.companyDetails')}</h2>

      <Form.Group controlId="profileImage" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.image.label')}</Form.Label>
        <Form.Control
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          disabled={uploading}
          data-testid="image-upload-input"
        />
        {uploading && <p>{t('components.ProfileEditForm.status.loading')}</p>}
      </Form.Group>

      <Form.Group controlId="Title" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.company.name')}</Form.Label>
        <Form.Control
          type="text"
          name="Title"
          value={userProfile.Title || ''}
          onChange={(e) => setUserProfile(prev => ({ ...prev, Title: e.target.value }))}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.company.address')}</Form.Label>
        <div 
          ref={geocoderContainerRef} 
          className="geocoder-container"
          style={{ minHeight: '38px' }}
        />
      </Form.Group>

      <Form.Group controlId="companyContact" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.contact.person')}</Form.Label>
        <Form.Control
          type="text"
          name="companyContact"
          value={publicData.companyContact || ''}
          onChange={handlePublicDataChange}
        />
      </Form.Group>

      <Form.Group controlId="contactNumber" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.contact.phone')}</Form.Label>
        <Form.Control
          type="text"
          name="contactNumber"
          value={publicData.contactNumber || ''}
          onChange={handlePublicDataChange}
        />
      </Form.Group>

      <Form.Group controlId="contactEmail" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.contact.email')}</Form.Label>
        <Form.Control
          type="email"
          name="contactEmail"
          value={publicData.contactEmail || ''}
          onChange={handlePublicDataChange}
        />
      </Form.Group>

      <Form.Group controlId="companyNumber" className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.company.orgNumber')}</Form.Label>
        <Form.Control
          type="text"
          name="companyNumber"
          value={publicData.companyNumber || ''}
          onChange={handlePublicDataChange}
        />
      </Form.Group>

      <h2 className="form-section-header mb-4">{t('components.ProfileEditForm.sections.about')}</h2>

      <Form.Group className="mb-3">
        <Form.Label>{t('components.ProfileEditForm.fields.company.description')}</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          name="description"
          value={publicData.description || ''}
          onChange={handlePublicDataChange}
          placeholder={t('components.ProfileEditForm.fields.services.placeholders.description')}
        />
      </Form.Group>

      <div className="mb-4">
        <Form.Label>{t('components.ProfileEditForm.fields.categories.label')}</Form.Label>
        <CategorySection 
          isEditMode={true}
          categories={publicData.category || []}
          onCategoryChange={handleCategoryChange}
        />
      </div>

      <h2 className="form-section-header mb-4">{t('components.ProfileEditForm.sections.services')}</h2>

      {[...Array(visibleOffers)].map((_, index) => {
        const offerNum = index + 1;
        const isIncomplete = (publicData[`offerHeading${offerNum}`]?.trim() || 
                            publicData[`offer${offerNum}`]?.trim()) && 
                            !isOfferComplete(offerNum);
        
        return (
          <div key={offerNum} className="offer-group mb-4">
            <Form.Group className="mb-2">
              <Form.Label>{t('components.ProfileEditForm.fields.services.title', { number: offerNum })}</Form.Label>
              <Form.Control
                type="text"
                name={`offerHeading${offerNum}`}
                value={publicData[`offerHeading${offerNum}`] || ''}
                onChange={handlePublicDataChange}
                placeholder={t('components.ProfileEditForm.fields.services.placeholders.title')}
                isInvalid={isIncomplete}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('components.ProfileEditForm.fields.services.description', { number: offerNum })}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name={`offer${offerNum}`}
                value={publicData[`offer${offerNum}`] || ''}
                onChange={handlePublicDataChange}
                placeholder={t('components.ProfileEditForm.fields.services.placeholders.description')}
                isInvalid={isIncomplete}
              />
              {isIncomplete && (
                <Form.Text className="text-danger">
                  {t('components.ProfileEditForm.validation.serviceRequired')}
                </Form.Text>
              )}
            </Form.Group>
            {offerNum > 1 && (
              <Button 
                variant="outline-danger" 
                size="sm"
                onClick={() => {
                  if (window.confirm(t('components.ProfileEditForm.validation.confirmDelete'))) {
                    const newPublicData = { ...publicData };
                    for (let i = offerNum; i < 5; i++) {
                      newPublicData[`offerHeading${i}`] = publicData[`offerHeading${i + 1}`] || '';
                      newPublicData[`offer${i}`] = publicData[`offer${i + 1}`] || '';
                    }
                    newPublicData[`offerHeading5`] = '';
                    newPublicData[`offer5`] = '';
                    setPublicData(newPublicData);
                    setVisibleOffers(visibleOffers - 1);
                  }
                }}
                className="mt-2"
              >
                {t('components.ProfileEditForm.actions.removeService', { number: offerNum })}
              </Button>
            )}
          </div>
        );
      })}
      
      {visibleOffers < 5 && (
        <Button 
          variant="outline-primary" 
          onClick={addNewOffer}
          className="mt-2"
          disabled={!isOfferComplete(visibleOffers)}
        >
          {t('components.ProfileEditForm.actions.addService')}
        </Button>
      )}
    </Form>
  );
};

export default ProfileEditForm;
