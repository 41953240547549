import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import './HelpPage.css';

const HelpPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${t('pages.help.title')} | Medla`;
    return () => {
      document.title = 'Medla';
    };
  }, [t]);

  const handleContactSupport = () => {
    const subject = encodeURIComponent('Hjälp - Medla');
    window.location.href = `mailto:support@medla.app?subject=${subject}`;
  };

  return (
    <div className="help-page">
      <div className="help-container">
        <div className="help-header">
          <h1>{t('pages.help.title')}</h1>
        </div>
        
        <div className="help-content">
          <p className="help-description">{t('pages.help.description')}</p>
          
          <div className="help-sections">
            <div className="help-section">
            </div>

            <div className="help-contact-section">
              <p className="help-contact-text">{t('pages.help.content.text3')}</p>
              <Button 
                variant="primary" 
                onClick={handleContactSupport}
                className="help-contact-button"
              >
                {t('pages.help.content.contactButton')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;