// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Alert, ProgressBar, Card, Row, Col, Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/theme.css';
import '../../styles/styles.css';
import './HomePage.css';
import { auth } from '../../authentication/Firebase';
import { VattenfallLogo, WpdLogo } from '../../assets/logos';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t } = useTranslation();
  const [profileCompletion, setProfileCompletion] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [userId, setUserId] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setIsLoggedIn(true); // Set login status
        fetchProfileCompletion(user.uid);
      } else {
        setIsLoggedIn(false); // Set login status
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchProfileCompletion = async (uid) => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-profile/${uid}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched profile data:", data);
        setProfileData(data);
        const completion = calculateProfileCompletion(data);
        setProfileCompletion(completion);
        setRecommendations(generateRecommendations(data));
        
        if (completion === 0) {
          setShowWelcomeModal(true);
        }
      } else if (response.status === 404) {
        setProfileData(null);
        setProfileCompletion(0);
        setRecommendations(checks);
        setShowWelcomeModal(true);
      } else {
        console.error('Failed to fetch profile:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const checks = [
    {
      group: 'profile',
      title: t('pages.home.profileChecks.profileImageUrl'),
      field: 'PublicData_y.profileImageUrl',
      description: 'Ladda upp en bild'
    },
    {
      group: 'profile', 
      title: 'Företagsnamn',
      field: 'Title',
      description: 'Ange företagets namn'
    },
    {
      group: 'contact',
      title: t('pages.home.profileChecks.address'),
      field: 'PublicData_y.location.address',
      description: 'Ange adress'
    },
    {
      group: 'contact',
      title: t('pages.home.profileChecks.companyContact'),
      field: 'PublicData_y.companyContact',
      description: 'Ange kontaktperson'
    },
    {
      group: 'contact',
      title: t('pages.home.profileChecks.contactNumber'),
      field: 'PublicData_y.contactNumber', 
      description: 'Ange telefonnummer'
    },
    {
      group: 'contact',
      title: t('pages.home.profileChecks.contactEmail'),
      field: 'PublicData_y.contactEmail',
      description: 'Ange e-postadress'
    },
    {
      group: 'company',
      title: t('pages.home.profileChecks.companyNumber'),
      field: 'PublicData_y.companyNumber',
      description: 'Ange organisationsnummer'
    },
    {
      group: 'company',
      title: t('pages.home.profileChecks.description'),
      field: 'PublicData_y.description',
      description: 'Beskriv verksamheten'
    }
  ];

  const calculateProfileCompletion = (profileData) => {
    if (!profileData?.PublicData_y) return 0;
    
    // Add some debugging
    console.log("Profile Data:", profileData);

    const filledFields = checks.filter(check => {
        const parts = check.field.split('.');
        let value = profileData;
        
        for (const part of parts) {
            value = value?.[part];
            if (value === undefined) break;
        }
        
        const isFieldFilled = value && String(value).trim() !== '';
        console.log(`Field ${check.field}: ${isFieldFilled ? 'filled' : 'empty'}`);
        return isFieldFilled;
    }).length;

    const percentage = Math.round((filledFields / checks.length) * 100);
    console.log(`Completion percentage: ${percentage}%`);
    return percentage;
  };

  const generateRecommendations = (profileData) => {
    // Always return all checks, don't filter any out
    return checks;
  };

  // Add this new function here
  const getCardDescription = (field) => {
    const descriptions = {
      'PublicData_y.profileImageUrl': 'Lägg till en profilbild för ditt företag',
      'Title': 'Lägg till din yrkestitel för att visa din roll',
      'PublicData_y.location.address': 'Lägg till företagets adress',
      'PublicData_y.companyContact': 'Lägg till kontaktperson för företaget',
      'PublicData_y.contactNumber': 'Lägg till telefonnummer för att bli kontaktbar',
      'PublicData_y.contactEmail': 'Lägg till företagets e-postadress',
      'PublicData_y.companyNumber': 'Lägg till organisationsnummer',
      'PublicData_y.description': 'Lägg till en beskrivning om erfarenheter och erbjudanden',

      
    };
    return descriptions[field] || 'Komplettera din profil';
  };

  const handleCardClick = (field) => {
    if (userId) {
      navigate(`/profile/${userId}/edit`, { 
        state: { activeField: field } 
      });
    } else {
      console.error('User ID is not available');
    }
  };

  const handleCloseWelcomeModal = () => setShowWelcomeModal(false);

  const CompletionCards = ({ recommendations, profileData, onCardClick }) => {
    const isTaskComplete = (field) => {
      const parts = field.split('.');
      let value = profileData;
      for (const part of parts) {
        value = value?.[part];
        if (value === undefined) break;
      }
      return value && String(value).trim() !== '';
    };

    // Sort tasks - incomplete first, then completed
    const sortedTasks = [...recommendations].sort((a, b) => {
      const aComplete = isTaskComplete(a.field);
      const bComplete = isTaskComplete(b.field);
      if (aComplete === bComplete) return 0;
      return aComplete ? 1 : -1;
    });

    return (
      <div className="completion-tasks">
        {sortedTasks.map((task, index) => {
          const isCompleted = isTaskComplete(task.field);
          return (
            <div 
              key={index}
              className={`completion-task ${isCompleted ? 'completed' : ''}`}
              onClick={() => onCardClick(task.field)}
            >
              <div className="completion-task-content">
                <h5 className="completion-task-title">{task.title}</h5>
                <p className="completion-task-description">{task.description}</p>
              </div>
              {isCompleted && (
                <span className="completion-task-check" aria-label="Completed">✓</span>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home-page">
      {profileCompletion !== null && profileCompletion < 100 && (
        <div className="content-wrapper">
          <div className="profile-completion-section">
            <div className="profile-completion-header">
              <div className="profile-completion-title">
                <h3>{t('pages.home.profileCompletion.title')}</h3>
                <span className="completion-percentage">
                  {t('pages.home.profileCompletion.percentComplete', { percent: profileCompletion })}
                </span>
              </div>
              <div className="profile-completion-progress">
                <div 
                  className="progress-bar" 
                  role="progressbar" 
                  style={{ width: `${profileCompletion}%` }} 
                  aria-valuenow={profileCompletion} 
                  aria-valuemin="0" 
                  aria-valuemax="100"
                />
              </div>
            </div>
            
            <CompletionCards 
              recommendations={recommendations}
              profileData={profileData}
              onCardClick={handleCardClick}
            />
          </div>
        </div>
      )}

      {!isLoggedIn && (
        <div className="hero-section">
          <div className="hero-content-wrapper">
            <div className="hero-content">
              <h1>
                <span className="break-after">{t('pages.home.hero.title')}</span>
              </h1>
              <p>{t('pages.home.hero.subtitle')}</p>
              <Link to="/search" className="btn btn-primary">{t('pages.home.hero.cta')}</Link>
            </div>
          </div>
          <div className="hero-image" />
        </div>
      )}

      <div className="content-wrapper">
        <div className="projects-section">
          <h2>{t('pages.home.projects.title')}</h2>
          <div className="project-cards">
            <Link to="/projekt/velinga" className="project-card">
              <div className="project-card-logo">
                <div className="vattenfall-logo">{VattenfallLogo}</div>
              </div>
              <div className="project-card-content">
                <h3>{t('pages.home.projects.velinga.name')}</h3>
                <p>{t('pages.home.projects.velinga.location')}</p>
              </div>
            </Link>
            <Link to="/projekt/tomasliden" className="project-card">
              <div className="project-card-logo">
                <div className="wpd-logo">
                  {WpdLogo}
                </div>
              </div>
              <div className="project-card-content">
                <h3>Tomasliden</h3>
                <p>Norsjö</p>
              </div>
            </Link>
          </div>
        </div>

        <div className="how-it-works-section">
          <div className="how-it-works-content">
            <h2>{t('pages.home.howItWorks.title')}</h2>
            <div className="steps-container">
              {['register', 'describe', 'complete'].map((step) => (
                <div key={step} className="step-card">
                  <h3>{t(`pages.home.howItWorks.steps.${step}.title`)}</h3>
                  <p>{t(`pages.home.howItWorks.steps.${step}.description`)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal 
        show={showWelcomeModal} 
        onHide={handleCloseWelcomeModal}
        className="welcome-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('pages.home.welcomeModal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="welcome-content">
            <h4 className="welcome-step">Ett sista steg...</h4>
            <p className="welcome-description">
              Lägg till en kort beskrivning av din verksamhet och dina kontaktuppgifter.
            </p>
            <div className="welcome-tip">
              <strong>Tips:</strong> Detta gör det enklare för kunder att hitta och kontakta dig!
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={handleCloseWelcomeModal}
            className="btn-close-modal"
          >
            {t('pages.home.welcomeModal.actions.close')}
          </Button>
          <Button 
            variant="primary" 
            onClick={() => {
              handleCloseWelcomeModal();
              navigate(`/profile/${userId}/edit`);
            }}
            className="btn-complete-profile"
          >
            {t('pages.home.welcomeModal.actions.completeProfile')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HomePage;
