import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ProjectPage.css';
import ProjectHeader from '../../components/ProjectHeader/ProjectHeader';
import ProjectInfo from '../../components/ProjectInfo/ProjectInfo';

const ProjectPage = ({ config }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!config) {
    return <div data-testid="project-loading">{t('pages.project.loading')}</div>;
  }

  return (
    <div className="project-page" data-testid="project-page">
      <ProjectHeader 
        config={config} 
        data-testid="project-header"
      />
      <ProjectInfo 
        name={config.projectName} 
        projectDescription={config.projectDescription}
        banner={config.headerImage}
        data-testid="project-info"
        projectLink={config.projectLink}  // Added this line
        staticMap={true}


      />
    </div>
  );
};

export default ProjectPage;
