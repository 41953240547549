// src/routes.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import FinishSignUp from "./pages/FinishSignup/FinishSignUp";
import ProfilePage from './pages/ProfilePage/ProfilePage';
import SearchPage from './pages/SearchPage/SearchPage';
import ProjectPageWrapper from './components/ProjectPageWrapper/ProjectPageWrapper';
import AdminLogin from './pages/AdminLogin/AdminLogin';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy';
import StylePage from './pages/StylePage/StylePage';
import FaqPage from "./pages/FaqPage/FaqPage";
import HelpPage from './pages/HelpPage/HelpPage';

const AppRoutes = () => {
  const isProd = process.env.REACT_APP_ENV === 'prod';

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/finishSignup" element={<FinishSignUp />} />
      <Route path="/finalize-login" element={<FinishSignUp />} />
      <Route path="/__/auth/action" element={<FinishSignUp />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/profile/:id" element={<ProfilePage />} />
      <Route path="/profile/:id/edit" element={<ProfilePage />} />
      <Route path="/projekt/:projectId" element={<ProjectPageWrapper />} />
      <Route path="/admin-login" element={<AdminLogin />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/faq" element={<FaqPage />} />
      {!isProd && <Route path="/style-guide" element={<StylePage />} />}
      <Route path="/help" element={<HelpPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
