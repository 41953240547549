import React, { useEffect } from 'react';

const Sitemap = () => {
  useEffect(() => {
    // Fetch the static sitemap.xml file
    fetch('/sitemap.xml')
      .then(response => response.text())
      .then(xmlText => {
        // Create a blob with the XML content
        const blob = new Blob([xmlText], { 
          type: 'application/xml;charset=utf-8'
        });
        
        // Create object URL and trigger download
        const url = URL.createObjectURL(blob);
        window.location.href = url;
        
        // Clean up
        setTimeout(() => URL.revokeObjectURL(url), 1000);
      })
      .catch(error => {
        console.error('Error serving sitemap:', error);
      });
  }, []);

  return null;
};

export default Sitemap; 