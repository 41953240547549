import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import MapComponent from '../../components/SearchPageMapComponent/SearchPageMapComponent';
import SkeletonCard from '../../components/SkeletonCard/SkeletonCard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SearchPage.css';



// Import the categories object
const CATEGORIES = {
  'bygg_och_konstruktion': 'Bygg och Konstruktion',
  'teknik_och_underhall': 'Teknik och Underhåll',
  'transport_och_fordon': 'Transport och Fordon',
  'affarstjanster_och_support': 'Affärstjänster och Support',
  'restaurang_och_catering': 'Restaurang och Catering',
  'boende': 'Boende'
};

const SearchPage = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [visibleCompanies, setVisibleCompanies] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mapCompanies, setMapCompanies] = useState([]); // New state for map data
  const [displayedCount, setDisplayedCount] = useState(25);
  const observerTarget = useRef(null);
  const [mapDataCache, setMapDataCache] = useState(null);
  const [highlightedCompanyId, setHighlightedCompanyId] = useState(null);
  const cardsContainerRef = useRef(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query') || '';

  const fetchCompanies = useCallback(async () => {
    setIsLoading(true);
    try {
      let url;
      let method;
      let body;

      if (query) {
        url = `${process.env.REACT_APP_BACKEND_URL}/api/vector-search`;
        method = 'POST';
        body = JSON.stringify({
          query: query,
          index: 'medla_users',
          page: 1,
          size: 1000
        });
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/get-all-index-docs?index=medla_users&page=1&size=1000`;
        method = 'GET';
        body = null;
      }

      // Fetch all data in one request
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        ...(body ? { body } : {}) // Only include body for POST requests
      });

      const data = await response.json();
      
      const processedCompanies = query ? data.results : 
        data.docs.map(doc => ({
          data: doc._source,
          _id: doc._id
        }));

      setCompanies(processedCompanies);
      setMapCompanies(processedCompanies); // Use same data for both map and list
      setVisibleCompanies(processedCompanies);
    } catch (error) {
      console.error(t('pages.search.errors.fetchCompanies'), error);
    } finally {
      setIsLoading(false);
    }
  }, [query, t]);

  useEffect(() => {
    fetchCompanies();

    // Add event listener for profile updates
    const handleProfileUpdate = () => {
      fetchCompanies();
    };

    window.addEventListener('profileUpdated', handleProfileUpdate);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('profileUpdated', handleProfileUpdate);
    };
  }, [fetchCompanies]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/current-user`, {
          credentials: 'include'
        });
        if (response.ok) {
          const userData = await response.json();
          setCurrentUser(userData);
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      if (!isMobile) {
        localStorage.removeItem('isMapView');
        setVisibleCompanies(companies);
      } else {
        setVisibleCompanies(companies);
      }
    };

    // Initial check
    handleResize();

    // Debounce the resize handler
    let resizeTimer;
    const debouncedResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(handleResize, 250);
    };

    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
      clearTimeout(resizeTimer);
    };
  }, [companies]);

  useEffect(() => {
    if (companies.length > 0) {
      let filtered = companies;
      
      console.log('Selected categories:', selectedCategories);
      
      if (selectedCategories.length > 0) {
        filtered = companies.filter(company => {
          // Access the categories through the data property
          const companyCategories = company.data?.PublicData_y?.category || [];
          
          console.log('Company categories:', companyCategories);
          
          return selectedCategories.some(selectedCat => 
            companyCategories.includes(selectedCat)
          );
        });
      }
      
      setVisibleCompanies(filtered);
    }
  }, [companies, selectedCategories]);

  const handleCardClick = useCallback((company) => {
    const profileId = company.data.uid || company.data.AuthorId;
    if (profileId) {
      navigate(`/profile/${profileId}`);
    } else {
      console.error('No valid profile ID found for company:', company);
    }
  }, [navigate]);

  const handleMarkerClick = useCallback((companyId) => {
    const company = companies.find(c => c.data.uid === companyId || c.data.AuthorId === companyId);
    if (company) {
      handleCardClick(company);
    }
  }, [companies, handleCardClick]);

  const handleBoundsChanged = useCallback((bounds, currentCompanies) => {
    // First, get the filtered companies based on categories
    let filteredCompanies = companies;
    if (selectedCategories.length > 0) {
      filteredCompanies = companies.filter(company => {
        const companyCategories = company.data?.PublicData_y?.category || [];
        return selectedCategories.some(selectedCat => 
          companyCategories.includes(selectedCat)
        );
      });
    }

    // Then filter by map bounds
    const companiesInBounds = filteredCompanies.filter(company => {
      const data = company.data || company._source || company;
      const location = data.PublicData_y?.location;
      
      if (location?.latitude && location?.longitude) {
        return bounds.contains([location.longitude, location.latitude]);
      }
      return false;
    });

    setVisibleCompanies(companiesInBounds);
  }, [companies, selectedCategories]);

  // Infinite scroll effect (keep this one)
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && displayedCount < visibleCompanies.length) {
          setDisplayedCount(prev => Math.min(prev + 25, visibleCompanies.length));
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [displayedCount, visibleCompanies.length]);

  // Clear cache when component unmounts
  useEffect(() => {
    return () => setMapDataCache(null);
  }, []);

  const handleCompanyHover = useCallback((companyId) => {
    setHighlightedCompanyId(companyId);
  }, []);

  // Add this effect to reset scroll position on mount
  useEffect(() => {
    if (cardsContainerRef.current) {
      cardsContainerRef.current.scrollLeft = 0;
    }
  }, []);

  // Modify the scroll handler to include smooth scrolling and snapping
  const handleScroll = useCallback((event) => {
    if (!cardsContainerRef.current || window.innerWidth > 768) return;

    const container = cardsContainerRef.current;
    const cardWidth = 280 + 35; // card width + gap
    
    // Get the current scroll position
    const scrollLeft = container.scrollLeft;
    // Calculate which card we're closest to
    const currentCard = Math.round(scrollLeft / cardWidth);

    // Remove 'snapped' class from all cards
    container.querySelectorAll('.card-wrapper').forEach(card => {
      card.classList.remove('snapped');
    });

    if (visibleCompanies[currentCard]) {
      const company = visibleCompanies[currentCard];
      const companyId = company.data?.uid || company.data?.AuthorId;
      setHighlightedCompanyId(companyId);

      // Add 'snapped' class to the current card
      const currentElement = container.children[currentCard];
      if (currentElement) {
        currentElement.classList.add('snapped');
      }
    }
  }, [visibleCompanies]);

  // Add debouncing to make the effect smoother
  useEffect(() => {
    const container = cardsContainerRef.current;
    if (container) {
      let scrollTimeout;
      
      const debouncedScroll = (event) => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => handleScroll(event), 50);
      };

      container.addEventListener('scroll', debouncedScroll);
      return () => {
        clearTimeout(scrollTimeout);
        container.removeEventListener('scroll', debouncedScroll);
      };
    }
  }, [handleScroll]);

  // Add these effects to handle scroll position
  useEffect(() => {
    const resetScroll = () => {
      if (cardsContainerRef.current) {
        // Try multiple methods to ensure it works
        cardsContainerRef.current.scrollLeft = 0;
        cardsContainerRef.current.scrollTo({
          left: 0,
          behavior: 'instant'
        });
      }
    };

    // Reset immediately
    resetScroll();

    // Reset after a short delay to ensure content is loaded
    const timeoutId = setTimeout(resetScroll, 100);

    // Reset after all content is loaded
    window.addEventListener('load', resetScroll);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('load', resetScroll);
    };
  }, []);

  // Also reset when companies data changes
  useEffect(() => {
    if (cardsContainerRef.current && companies.length > 0) {
      cardsContainerRef.current.scrollLeft = 0;
    }
  }, [companies]);

  const handleCategoryFilter = useCallback((categoryKey) => {
    setSelectedCategories(prev => {
      if (prev.includes(categoryKey)) {
        return prev.filter(c => c !== categoryKey);
      }
      return [...prev, categoryKey];
    });
  }, []);

  useEffect(() => {
    // When component mounts
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.height = '100%';

    // Cleanup when component unmounts
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.height = '';
    };
  }, []);

  return (
    <div className="search-page" data-testid="search-page">
      <div className="content">
        <div className="left-panel">
          <div className="search-bar">
            {/* ... search bar content ... */}
          </div>
          <div className="tag-filters">
            {Object.entries(CATEGORIES).map(([key, displayName]) => (
              <button
                key={key}
                className={`tag-filter ${selectedCategories.includes(key) ? 'active' : ''}`}
                onClick={() => handleCategoryFilter(key)}
              >
                {displayName}
              </button>
            ))}
          </div>
          <div className="cards-container" ref={cardsContainerRef}>
            {visibleCompanies.map((company, index) => (
              <div 
                key={index}
                className={`card-wrapper ${
                  (company.data?.uid || company.data?.AuthorId) === highlightedCompanyId 
                    ? 'card-active' 
                    : ''
                }`}
                onMouseEnter={() => handleCompanyHover(company.data?.uid || company.data?.AuthorId)}
                onMouseLeave={() => handleCompanyHover(null)}
              > 
                <CompanyCard
                  company={company}
                  handleCardClick={() => handleCardClick(company)}
                  currentUser={currentUser}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="map-container" data-testid="map-container">
        <MapComponent
          companies={visibleCompanies}
          onMarkerClick={handleMarkerClick}
          center={[14.6357, 63.1792]}
          zoom={4}
          onBoundsChanged={handleBoundsChanged}
          staticMap={false}
          highlightedCompanyId={highlightedCompanyId}
        />
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
