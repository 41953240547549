import { getResizedImageUrl } from '../utils/imageUtils';

const STORAGE_URL = "https://firebasestorage.googleapis.com/v0/b/medla-auth.appspot.com/o";

export const velingaConfig = {
  title: 'Välkommen till Velinga',
  description: 'I början av 2024 påbörjade Vattenfall byggnationen av Velinga vindkraftpark med 12 vindkraftverk med en totalhöjd av 180 meter. Projektområdet ligger i Tidaholms kommun i Västra Götalands län.',
  headerImage: `${STORAGE_URL}/customer_assets%2Fvattenfall%2FVattenfallGronhult.a2afece2.png?alt=media&token=ca8fdaea-a2f1-4e66-bffc-76411a89c8aa`,
  companies: [
    {
      name: 'Lennartsson bygg ab',
      logo: getResizedImageUrl(`${STORAGE_URL}/assets%2Fprojects%2Fvelinga%2Flennartsson.png?alt=media`, 200),
      description: 'Velinga Bygdegård, 522 91 Tidaholm, Sverige',
      tags: ['Bemanning', 'Betong', 'Bygg'],
      icon: `${STORAGE_URL}/assets%2Fvattenfall_logo.png?alt=media&token=28f8e6c1-0d0e-4f16-995f-07c7fa142b05`,
    },
    // Add more companies...
  ],
  coordinates: {
    lat: 58.09215,
    lng: 14.009379,
  },

  popularBranches: ['Kost och logi', 'Byggentreprenad', 'Anläggning', 'Servicetjänster', 'Transport', 'Övrigt'],
  projectName: 'Velinga',
  projectDescription: 'I början av 2024 påbörjade Vattenfall byggnationen av Velinga vindkraftpark med 12 vindkraftverk med en totalhöjd av 180 meter. Projektområdet ligger i Tidaholms kommun i Västra Götalands län. I januari 2024 etablerades ett platskontor och därefter fortsätter arbetet med infrastruktur såsom vägar, fundament, uppställningsplatser och kabeldragning. Under sommar 2025 påbörjas transport och montering av vindkraftverken. Parken beräknas vara i drift i början av 2026.',
  projectLink: 'https://projekt.vattenfall.se/vindprojekt/landbaserad-vindkraft/velinga',
  projectStatus: 'Beviljat',
  projectMapTooltip: 'Projekt - Velinga',

};

// Add more project configurations as needed
export const tomaslidenConfig = {
    title: 'Välkommen till Tomasliden',
    description: 'wpd planerar att uppföra 10 vindkraftverk med 200 m totalhöjd på Tomasliden. Projektområdet ligger i Norsjö kommun, cirka 10 km väster om Bastuträsk och 20 km sydost om samhället Norsjö.',
    headerImage: 'https://firebasestorage.googleapis.com/v0/b/medla-auth.appspot.com/o/customer_assets%2Fwpd%2Fwpd_tomasliden_banner.jpg?alt=media&token=61896644-cc23-453a-8977-36ac9f5da4b5',
    companies: [
      {
        name: 'Andersson Konstruktion AB',
        logo: getResizedImageUrl(`${STORAGE_URL}/assets%2Fprojects%2Ftomasliden%2Fandersson.png?alt=media`, 200),
        description: 'Tomasliden 123, 456 78 Vindstad, Sverige',
        tags: ['Konstruktion', 'Projektledning', 'Vindkraft'],
        icon: `${STORAGE_URL}/assets%2Fwpd_logo.png?alt=media&token=a80f0e99-1950-4ed7-923b-ca166510bbb8`,
      },
      // Add more companies...
    ],
    coordinates: {
      lat: 64.914148,
      lng: 19.483077,
    },
    popularBranches: ['Vindkraftteknik', 'Miljökonsultation', 'Elinstallation', 'Markarbeten', 'Logistik'],
    projectName: 'Tomasliden',
    projectDescription: 'wpd planerar att uppföra 10 vindkraftverk med 200 m totalhöjd på Tomasliden. Projektområdet ligger i Norsjö kommun, cirka 10 km väster om Bastuträsk och 20 km sydost om samhället Norsjö. Förstärkning och byggnation av tillfartsvägar och vägar inom vindkraftsparken beräknas starta i början av november 2022. Fortsatt byggnation av vägar, kranplaner samt anläggande av fundament och kablar planeras ske med start våren 2023. Installation av vindkraftverken planeras ske under sommaren 2024.',
    projectLink: 'https://www.wpd.se/aktuella-projekt/vind/tomasliden/',
    projectStatus: 'Under konstruktion',
    projectMapTooltip: 'Projekt - Tomasliden',

  };
