import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import CompanyCard from '../CompanyCard/CompanyCard';
import './CompanyList.css';
import useAuth from '../../hooks/useAuth';

const CompanyList = ({ companies }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const displayCompanies = isMobile ? companies : (showAll ? companies : companies.slice(0, 8));

  if (!companies || companies.length === 0) {
    return null;
  }

  const handleCardClick = (company) => {
    navigate(`/profile/${company.data.AuthorId}`);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(companies.map(company => ({
      [t('components.CompanyList.export.columns.name')]: company.data.Title || '',
      [t('components.CompanyList.export.columns.title')]: company.data.Title || '',
      [t('components.CompanyList.export.columns.email')]: company.data.EmailAddress || '',
      [t('components.CompanyList.export.columns.phone')]: company.data.contactNumber || '',
      [t('components.CompanyList.export.columns.address')]: company.data.PublicData_y?.location?.address || '',
      [t('components.CompanyList.export.columns.category')]: company.data.PublicData_y?.category?.join(', ') || '',
      [t('components.CompanyList.export.columns.offerHeading')]: company.data.offerHeading1 || '',
      [t('components.CompanyList.export.columns.offer')]: company.data.offer1 || '',
    })));
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, t('components.CompanyList.export.sheetName'));
    XLSX.writeFile(workbook, t('components.CompanyList.export.filename'));
  };

  return (
    <div className="company-list-container">
      <div className="company-list-header">
        <h2>{t('components.CompanyList.title')}</h2>
        <button className="export-btn" onClick={exportToExcel}>
          {t('components.CompanyList.actions.export')}
        </button>
      </div>
      <div className="company-grid">
        {displayCompanies.map((company, index) => (
          <CompanyCard
            key={index}
            company={company}
            handleCardClick={() => handleCardClick(company)}
            currentUser={user}
          />
        ))}
      </div>
      {!isMobile && companies.length > 8 && (
        <button className="show-more-btn" onClick={() => setShowAll(!showAll)}>
          {showAll ? t('components.CompanyList.actions.showLess') : t('components.CompanyList.actions.showMore')}
        </button>
      )}
    </div>
  );
};

export default CompanyList;
