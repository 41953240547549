import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useCookieConsent } from '../../context/CookieConsentContext';
import './CookiePolicy.css';

const CookiePolicy = () => {
  const { hasConsent, giveConsent, withdrawConsent } = useCookieConsent();
  const { t } = useTranslation();

  return (
    <Container className="cookie-policy">
      <h1>{t('cookiePolicy.title')}</h1>
      
      <section>
        <p>{t('cookiePolicy.description')}</p>
        
        <h3>{t('cookiePolicy.types.essential.title')}</h3>
        <p>{t('cookiePolicy.types.essential.description')}</p>

        <h3>{t('cookiePolicy.types.analytics.title')}</h3>
        <p>{t('cookiePolicy.types.analytics.description')}</p>

        <div className="consent-status">
          <p>
            <strong>{t('cookiePolicy.currentStatus')}: </strong>
            {hasConsent 
              ? t('cookiePolicy.statusAccepted')
              : t('cookiePolicy.statusDeclined')}
          </p>
          <button 
            onClick={hasConsent ? withdrawConsent : giveConsent}
            className={`btn ${hasConsent ? 'btn-outline-primary' : 'btn-primary'}`}
          >
            {hasConsent 
              ? t('cookiePolicy.withdrawConsent')
              : t('cookiePolicy.giveConsent')}
          </button>
        </div>
      </section>
    </Container>
  );
};

export default CookiePolicy;