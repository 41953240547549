import React from 'react';
import { useParams } from 'react-router-dom';
import ProjectPage from '../../pages/ProjectPage/ProjectPage';
import { velingaConfig, tomaslidenConfig } from '../../config/projectConfig';

const ProjectPageWrapper = () => {
  const { projectId } = useParams();

  const getProjectConfig = (id) => {
    switch (id) {
      case 'velinga':
        return velingaConfig;
      case 'tomasliden':
        return tomaslidenConfig;
      default:
        return null;
    }
  };

  const config = getProjectConfig(projectId);

  if (!config) {
    return <div>Project not found</div>;
  }

  return <ProjectPage config={config} />;
};

export default ProjectPageWrapper;

