// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AppRoutes from './routes';
import '../src/styles/theme.css';
import '../src/styles/styles.css';
import { CookieConsentProvider } from './context/CookieConsentContext';
import CookieBanner from './components/CookieBanner/CookieBanner';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import Sitemap from './components/Sitemap/Sitemap';

const SentryFallback = () => {
  return (
    <div className="error-boundary">
      <h2>Oops! Something went wrong</h2>
      <p>We've been notified and are working to fix the issue.</p>
    </div>
  );
};

const LegacyProfileRedirect = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchUserAndRedirect = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-by-legacy-id/${id}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }

        const data = await response.json();
        
        if (data && data.AuthorId) {
          navigate(`/profile/${data.AuthorId}`, { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      } catch (error) {
        console.error('Error in fetchUserAndRedirect:', error);
        navigate('/', { replace: true });
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndRedirect();
  }, [id, navigate]);
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  return null;
};

function App() {
  return (
    <Sentry.ErrorBoundary fallback={SentryFallback}>
      <CookieConsentProvider>
        <Router>
          <div className="App">
            <header>
              <Header />
            </header>
            <main>
              <Routes>
                <Route path="/profile/:id" element={<ProfilePage />} />
                <Route path="/c/:displayName/:id" element={<LegacyProfileRedirect />} />
                <Route path="/c/:id" element={<LegacyProfileRedirect />} />
                <Route path="/sitemap.xml" element={<Sitemap />} />
                <Route path="*" element={<AppRoutes />} />
              </Routes>
            </main>
            <footer>
              <Footer />
            </footer>
            <CookieBanner />
          </div>
        </Router>
      </CookieConsentProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
