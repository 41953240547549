import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './FaqPage.css';

const FaqPage = () => {
  const { t } = useTranslation();
  
  useEffect(() => {
    document.title = `${t('pages.faq.title')} | Medla`;
    return () => {
      document.title = 'Medla'; // Reset title when component unmounts
    };
  }, [t]);

  const faqItems = [
    {
      question: t('pages.faq.questions.q1'),
      answer: t('pages.faq.answers.a1')
    },
    {
      question: t('pages.faq.questions.q2'),
      answer: t('pages.faq.answers.a2')
    },
    {
      question: t('pages.faq.questions.q3'),
      answer: t('pages.faq.answers.a3')
    },
    {
      question: t('pages.faq.questions.q4'),
      answer: t('pages.faq.answers.a4')
    },
    {
      question: t('pages.faq.questions.q5'),
      answer: t('pages.faq.answers.a5')
    },

    {
      question: t('pages.faq.questions.q7'),
      answer: t('pages.faq.answers.a7')
    },
    {
      question: t('pages.faq.questions.q8'),
      answer: (
        <>
          {t('pages.faq.answers.a8')}
          <a 
            href="mailto:support@medla.app"
            className="text-decoration-none"
          >
            {t('pages.faq.email.support')}
          </a>
        </>
      )
    }
  ];

  return (
    <div className="faq-page">
      <div className="faq-container">
        <h1 className="mb-4">{t('pages.faq.title')}</h1>
        
        <Accordion defaultActiveKey={null} alwaysOpen>
          {faqItems.map((item, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>
                {item.question}
              </Accordion.Header>
              <Accordion.Body>
                {item.answer}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqPage;
