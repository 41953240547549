// src/pages/ProfilePage/ProfilePage.js
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Button, Alert, Container, Row, Col } from 'react-bootstrap';
import useAuth from '../../hooks/useAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/theme.css';
import '../../styles/styles.css';
import './ProfilePage.css';
import { auth, storage } from '../../authentication/Firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import ProfileEditForm from '../../components/ProfilePage/ProfileEditForm';
import OffersDisplay from '../../components/ProfilePage/OffersDisplay';
import CategorySection from '../../components/ProfilePage/CategorySection';
import ProfilePageMap from '../../components/ProfilePage/ProfilePageMap';
import { FaPhone, FaEnvelope, FaEdit, FaBuilding } from 'react-icons/fa'; // Add this import at the top
import { useTranslation } from 'react-i18next';
import { useCookieConsent } from '../../context/CookieConsentContext';

const ProfilePage = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.pathname.endsWith('/edit');
  const { t } = useTranslation();
  const { hasConsent } = useCookieConsent();

  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [editMode, setEditMode] = useState(isEditMode);
  const [publicData, setPublicData] = useState({});
  const [message, setMessage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [mapKey, setMapKey] = useState(0);

  useEffect(() => {
    setEditMode(isEditMode);
  }, [isEditMode, location.pathname]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // Get the idToken only if it's the current user's profile
        const idToken = user && user.uid === id ? await auth.currentUser?.getIdToken(true) : null;
        
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-user-profile/${id}`, {
          method: 'GET',
          headers: {
            ...(idToken ? { 'Authorization': `Bearer ${idToken}` } : {}),
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }

        const data = await response.json();

        if (data.error) {
          setUserProfile({});
          setPublicData({});
        } else {
          console.log("Received user profile data:", data);
          // The data is now directly in the response, not in _source
          setUserProfile(data);
          setPublicData(data.PublicData_y || {});
          setProfileImageUrl(data.PublicData_y?.profileImageUrl || '');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setUserProfile({});
        setPublicData({});
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [id, user]);

  // Add this useEffect to handle message timeout
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  if (loading) {
    return <div data-testid="loading-state">{t('pages.profile.loading')}</div>;
  }

  const isOwnProfile = user && (user.uid === id || user.uid === userProfile.AuthorId);

  // If the profile is not published and it's not the owner, show a "not found" message
  if (userProfile.State_y !== 'published' && !isOwnProfile) {
    return (
      <Container className="profile-page py-5">
        <Alert variant="info">
          This profile is not available.
        </Alert>
      </Container>
    );
  }



  const handleEditToggle = () => {
    if (editMode) {
      // If already in edit mode, navigate back to view mode
      navigate(`/profile/${id}`);
    } else {
      // Navigate to edit mode
      navigate(`/profile/${id}/edit`);
    }
    // editMode state will be updated via useEffect when route changes
  };

  const handlePublicDataChange = (e) => {
    const { name, value } = e.target;
    setPublicData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      console.log("Starting save process...");
      console.log("Current userProfile:", userProfile);
      console.log("Current publicData:", publicData);

      let updatedPublicData = { ...publicData };

      // Check if the address has changed
      const oldAddress = userProfile?.PublicData_y?.location?.address || '';
      const newAddress = publicData.location?.address || '';

      if (newAddress && newAddress !== oldAddress) {
        console.log("Address changed, geocoding...");
        
        // Ensure the address is properly encoded
        const encodedAddress = encodeURIComponent(JSON.stringify([newAddress]));
        
        const geocodeResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/geocode?addresses=${encodedAddress}`, {
          method: 'GET',
          credentials: 'include',
        });
        
        console.log("Geocode response status:", geocodeResponse.status);
        
        if (!geocodeResponse.ok) {
          const errorText = await geocodeResponse.text();
          throw new Error(`Failed to geocode address: ${geocodeResponse.status} ${geocodeResponse.statusText}. ${errorText}`);
        }
        
        const geocodeData = await geocodeResponse.json();
        console.log("Geocode data:", geocodeData);
        
        if (geocodeData.length > 0 && geocodeData[0].coordinates) {
          updatedPublicData.location = {
            address: newAddress,
            latitude: geocodeData[0].coordinates[1],
            longitude: geocodeData[0].coordinates[0]
          };
          console.log("Updated location:", updatedPublicData.location);
        } else {
          console.warn("Could not geocode the provided address.");
          setMessage({ type: 'warning', text: 'Could not geocode the provided address. Saving profile without updating coordinates.' });
        }
      }

      // Prepare the data to be sent
      const dataToSend = {
        PublicData_y: {
          ...userProfile.PublicData_y, // Keep existing data
          ...updatedPublicData, // Override with updated data
          lastModified: new Date().toISOString(),
          lastModifiedBy: user.uid
        },
        id: id,
        Title: userProfile.Title
      };

      console.log("Updating profile...");
      console.log("Data being sent:", dataToSend);
      
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await auth.currentUser?.getIdToken()}`
        },
        credentials: 'include',
        body: JSON.stringify(dataToSend),
      });

      console.log("Update profile response status:", response.status);

      if (response.ok) {
        const responseData = await response.json();
        
        // Update states and navigate first
        setUserProfile(prev => ({
          ...prev,
          PublicData_y: dataToSend.PublicData_y,
          Title: dataToSend.Title
        }));
        setPublicData(dataToSend.PublicData_y);
        setEditMode(false);
        setMapKey(prevKey => prevKey + 1);
        
        // Navigate before setting the message
        navigate(`/profile/${id}`);
        
        // Set message after navigation
        setMessage({ type: 'success', text: 'Din profil har uppdaterats!' });
        
        // Dispatch event
        const event = new CustomEvent('profileUpdated');
        window.dispatchEvent(event);
      } else {
        const errorData = await response.json();
        console.error("Error updating profile:", errorData);
        setMessage({ type: 'danger', text: `Error updating profile: ${errorData.error}` });
      }
    } catch (error) {
      console.error('Error in handleSave:', error);
      setMessage({ type: 'danger', text: `An unexpected error occurred: ${error.message}` });
    }
  };

  // Move the offers preparation logic near the top with other data preparation
  const offers = [
    { heading: publicData.offerHeading1, content: publicData.offer1 },
    { heading: publicData.offerHeading2, content: publicData.offer2 },
    { heading: publicData.offerHeading3, content: publicData.offer3 },
    { heading: publicData.offerHeading4, content: publicData.offer4 },
    { heading: publicData.offerHeading5, content: publicData.offer5 },
  ].filter(offer => offer.content); // Filter out undefined or empty offers

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setUploading(true);
      
      // Validate file type and size
      if (!file.type.startsWith('image/')) {
        throw new Error('Invalid file type. Please upload an image.');
      }
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('File too large. Maximum size is 5MB.');
      }

      const imageId = uuidv4();
      const storageRef = ref(storage, `uploads/companies/${id}/images/profile/${imageId}.webp`);
      
      // Delete old profile image if it exists
      if (profileImageUrl) {
        try {
          // Get both original and optimized paths
          const oldUrl = new URL(profileImageUrl);
          const oldPathBase = decodeURIComponent(oldUrl.pathname.split('/o/')[1].split('?')[0]);
          const oldBaseName = oldPathBase.split('/').pop().split('_')[0];
          
          // Delete original
          const oldUploadRef = ref(storage, `uploads/companies/${id}/images/profile/${oldBaseName}`);
          await deleteObject(oldUploadRef).catch(() => {});
          
          // Delete all optimized versions
          const sizes = [200, 400, 800];
          await Promise.all(sizes.map(size => {
            const oldOptimizedRef = ref(storage, `optimized/companies/${id}/images/profile/${oldBaseName}_${size}x${size}.webp`);
            return deleteObject(oldOptimizedRef).catch(() => {});
          }));
        } catch (error) {
          console.log('Error deleting old images:', error);
        }
      }

      // Upload with metadata
      await uploadBytes(storageRef, file, {
        contentType: file.type,
        customMetadata: {
          'cacheControl': 'public,max-age=31536000'
        }
      });

      // Wait for resize function to complete
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Get the optimized image URL for profile (400x400)
      const optimizedFilename = `${imageId}_400x400.webp`;
      const optimizedPath = `optimized/companies/${id}/images/profile/${optimizedFilename}`;
      const optimizedRef = ref(storage, optimizedPath);
      const downloadURL = await getDownloadURL(optimizedRef);
      
      setProfileImageUrl(downloadURL);
      setPublicData(prev => ({
        ...prev,
        profileImageUrl: downloadURL
      }));
    } catch (error) {
      console.error('Error uploading image:', error);
      setMessage({
        text: error.message || 'Failed to upload image',
        type: 'error'
      });
    } finally {
      setUploading(false);
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    const categories = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setPublicData(prev => ({ ...prev, category: categories }));
  };

  const incrementAnalytics = async (type) => {
    // Skip analytics if no consent
    if (!hasConsent) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/increment-analytics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          userId: id,
          type: type
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.error || 'Failed to increment analytics');
      }
    } catch (error) {
      console.error(`Error incrementing ${type} views:`, error);
    }
  };

  const handleShowEmail = () => {
    if (!showEmail) {
      setShowEmail(true);
      incrementAnalytics('email');
    } else if (userProfile.PublicData_y?.contactEmail) {
      window.location.href = `mailto:${userProfile.PublicData_y.contactEmail}`;
    }
  };

  const handleShowPhone = () => {
    if (!showPhone) {
      setShowPhone(true);
      incrementAnalytics('phone');
    } else if (userProfile.PublicData_y?.contactNumber) {
      window.location.href = `tel:${userProfile.PublicData_y.contactNumber}`;
    }
  };

  return (
    <Container className={`profile-container ${editMode ? 'edit-mode' : ''}`}>
      {/* Show message alerts */}
      {message && (
        <Alert 
          variant={message.type === 'error' ? 'danger' : 'success'}
          className="position-fixed start-50 translate-middle-x mt-3 z-3"
          style={{ maxWidth: '90%', width: 'auto', top: '80px' }}
          data-testid="profile-message"
        >
          {message.text}
        </Alert>
      )}

      {/* Show unavailable message for unpublished profiles */}
      {userProfile && userProfile.State_y !== 'published' && !isOwnProfile && (
        <div role="alert" data-testid="profile-unavailable" className="alert alert-warning">
          {t('pages.profile.alerts.unavailable')}
        </div>
      )}

      {/* Show pending review message for unpublished own profiles */}
      {userProfile && userProfile.State_y !== 'published' && isOwnProfile && (
        <Alert variant="warning" className="sticky-top" data-testid="profile-pending">
          {t('pages.profile.alerts.pendingReview')}
        </Alert>
      )}

      <div className="profile-header">
        {isOwnProfile && (
          <Button 
            variant={editMode ? "secondary" : "primary"}
            onClick={handleEditToggle}
            className="edit-profile-btn"
            data-testid="profile-edit-button"
          >
            <FaEdit className="me-2" />
            {editMode ? t('pages.profile.actions.cancelEdit') : t('pages.profile.actions.edit')}
          </Button>
        )}
        <div className="profile-image-container">
          {profileImageUrl ? (
            <img 
              src={profileImageUrl}
              alt="Profile" 
              className="profile-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
                e.target.parentNode.appendChild(document.createElement('div')).className = 'profile-image-placeholder';
                e.target.parentNode.lastChild.innerHTML = '<i class="fas fa-building"></i>';
              }}
            />
          ) : (
            <div className="profile-image-placeholder">
              <FaBuilding />
            </div>
          )}
        </div>
        <div className="profile-title-container">
          <h1>{userProfile.Title || userProfile.DisplayName}</h1>
          <div className="company-info">
            {userProfile.PublicData_y?.location?.address || ''}
          </div>
        </div>
      </div>

      <Row>
        <Col md={7}>  {/* Removed pe-4 */}
          {editMode ? (
            <ProfileEditForm
              userProfile={userProfile}
              setUserProfile={setUserProfile}
              publicData={publicData}
              setPublicData={setPublicData}
              handlePublicDataChange={handlePublicDataChange}
              handleImageUpload={handleImageUpload}
              uploading={uploading}
              message={message}
              handleCategoryChange={handleCategoryChange}  // Add this prop
            />
          ) : (
            <div className="profile-main">
              <div className="about-section">
                <h2>{t('pages.profile.sections.about.title')}</h2>
                <p>{userProfile.PublicData_y?.description}</p>
              </div>

              <OffersDisplay 
                offers={[
                  { 
                    heading: userProfile.PublicData_y?.offerHeading1, 
                    content: userProfile.PublicData_y?.offer1 
                  },
                  { 
                    heading: userProfile.PublicData_y?.offerHeading2, 
                    content: userProfile.PublicData_y?.offer2 
                  },
                  { 
                    heading: userProfile.PublicData_y?.offerHeading3, 
                    content: userProfile.PublicData_y?.offer3 
                  },
                  { 
                    heading: userProfile.PublicData_y?.offerHeading4, 
                    content: userProfile.PublicData_y?.offer4 
                  },
                  { 
                    heading: userProfile.PublicData_y?.offerHeading5, 
                    content: userProfile.PublicData_y?.offer5 
                  }
                ].filter(offer => offer.heading && offer.content)} 
              />
              
              {userProfile.PublicData_y?.category && userProfile.PublicData_y.category.length > 0 && (
                  <CategorySection 
                    isEditMode={false}
                    categories={userProfile.PublicData_y.category}
                    onCategoryChange={handleCategoryChange}
                  />
              )}
            </div>
          )}
        </Col>

        <Col md={5}>  {/* Removed ps-4 */}
          <div className="profile-sidebar">
            <ProfilePageMap 
              userProfile={userProfile}
              mapKey={mapKey}
              isProfilePage={true}
              className="profile-map"
              dragPan={false}
              dragRotate={false}
              scrollZoom={false}
              touchZoom={false}
              touchRotate={false}
              keyboard={false}
              doubleClickZoom={false}
            />

            <div className="contact-buttons">
              {userProfile?.PublicData_y?.contactEmail && (
                <Button 
                  variant="primary" 
                  onClick={handleShowEmail}
                  className="w-100"
                  data-testid="show-email-button"
                >
                  <FaEnvelope className="me-2" />
                  {showEmail ? (
                    <span style={{ textDecoration: 'underline' }}>
                      {userProfile.PublicData_y.contactEmail}
                    </span>
                  ) : t('pages.profile.buttons.showEmail')}
                </Button>
              )}

              {userProfile?.PublicData_y?.contactNumber && (
                <Button 
                  variant="primary" 
                  onClick={handleShowPhone}
                  className="w-100"
                  data-testid="show-phone-button"
                >
                  <FaPhone className="me-2" />
                  {showPhone ? (
                    <span style={{ textDecoration: 'underline' }}>
                      {userProfile.PublicData_y.contactNumber}
                    </span>
                  ) : t('pages.profile.buttons.showPhone')}
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {editMode && (
        <div className="sticky-save-container">
          <Button 
            variant="secondary" 
            onClick={() => navigate(`/profile/${id}`)}
            data-testid="profile-cancel-button"
          >
            {t('pages.profile.actions.cancel')}
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={uploading}
            data-testid="profile-save-button"
          >
            {uploading ? t('pages.profile.actions.saving') : t('pages.profile.actions.save')}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default ProfilePage;
