import React from 'react';
import { useTranslation } from 'react-i18next';
import './ProjectInfo.css';

const ProjectInfo = ({ name, projectDescription, banner, projectLink }) => {
  const { t } = useTranslation();

  return (
    <div className="project-info">
      <div className="project-info__content">
        <h2>{t('components.ProjectInfo.title')}</h2>
        <h3>{name || t('components.ProjectInfo.defaults.name')}</h3>
        <p>
          {projectDescription || t('components.ProjectInfo.defaults.description')}
          {projectLink && (
            <>
              <br /><br />
              <a href={projectLink} className="project-info__link">
                Läs mer
              </a>
            </>
          )}
        </p>
      </div>
      {banner && (
        <div className="project-info__image">
          <img src={banner} alt={name} />
        </div>
      )}
    </div>
  );
};

export default ProjectInfo;
