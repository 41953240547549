import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sv from '../language/sv';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      sv: {
        translation: sv,
      },
    },
    lng: 'sv', // force language to Swedish
    fallbackLng: 'sv',
    debug: process.env.NODE_ENV === 'development',
    
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;