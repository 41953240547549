import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectMapComponent from '../ProjectMap/ProjectMap';
import CompanyList from '../CompanyList/CompanyList';
import { getResizedImageUrl } from '../../utils/imageUtils';
import './ProjectHeader.css';

const ProjectHeader = ({ config }) => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const { 
    title, 
    description, 
    coordinates, 
    headerImage, 
    companies: configCompanies 
  } = config;

  useEffect(() => {
    fetchCompanies();
  }, [coordinates]);

  const fetchCompanies = async (bounds) => {
    try {
      let url;
      const radius = 0.5;
      const defaultCoords = {
        lat: t('components.ProjectHeader.defaults.coordinates.lat'),
        lng: t('components.ProjectHeader.defaults.coordinates.lng')
      };

      if (bounds) {
        url = `${process.env.REACT_APP_BACKEND_URL}/get-companies-in-bounds?top=${bounds.getNorth()}&right=${bounds.getEast()}&bottom=${bounds.getSouth()}&left=${bounds.getWest()}`;
      } else if (coordinates?.lat && coordinates?.lng) {
        const { lat, lng } = coordinates;
        url = `${process.env.REACT_APP_BACKEND_URL}/get-companies-in-bounds?top=${lat + radius}&right=${lng + radius}&bottom=${lat - radius}&left=${lng - radius}`;
      } else {
        console.warn(t('components.ProjectHeader.errors.noCoordinates'));
        const { lat, lng } = defaultCoords;
        url = `${process.env.REACT_APP_BACKEND_URL}/get-companies-in-bounds?top=${lat + radius}&right=${lng + radius}&bottom=${lat - radius}&left=${lng - radius}`;
      }

      const response = await fetch(url);
      const text = await response.text();
      const data = JSON.parse(text);
      setCompanies(data.docs || []);
    } catch (error) {
      console.error(t('components.ProjectHeader.api.fetchError'), error);
      setCompanies([]);
    }
  };

  const projectIcon = {
    position: [coordinates?.lng || t('components.ProjectHeader.defaults.coordinates.lng'), 
               coordinates?.lat || t('components.ProjectHeader.defaults.coordinates.lat')],
    icon: config.companies[0]?.icon,
    zIndex: 20
  };

  // Get the optimized header image (800x800 for best quality)
  const optimizedHeaderImage = config.headerImage ? getResizedImageUrl(config.headerImage, 800) : null;

  return (
    <div className="project-header-container" data-testid="project-header">
      <div className="project-header">
        <div className="project-header-info">
          <h1 data-testid="project-title">{title}</h1>
          <p data-testid="project-description">{description}</p>
        </div>
        <div className="project-header-map">
          <ProjectMapComponent 
            companies={companies}
            center={coordinates?.lat && coordinates?.lng 
              ? [coordinates.lng, coordinates.lat] 
              : [t('components.ProjectHeader.defaults.coordinates.lng'), 
                 t('components.ProjectHeader.defaults.coordinates.lat')]}
            zoom={7.0} // Use a number instead of a translation string
            disableClick={true}
            onBoundsChanged={fetchCompanies}
            icons={[projectIcon]}
            staticMap={true}
            searchRadius={0.2}
            projectMapTooltip={config.projectMapTooltip} // Add this line

          />
        </div>
      </div>
      <CompanyList companies={companies} />
    </div>
  );
};

export default ProjectHeader;
