import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MedlaLogo } from '../../assets/medla_logo.svg';
import './Footer.css';

const Footer = () => {
  const location = useLocation();
  const { t } = useTranslation();
  
  const noFooterRoutes = ['/search', '/some-other-page'];

  if (noFooterRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Column 1 - Logo and Description */}
        <div className="footer-column">
          <div className="footer-logo">
            <MedlaLogo />
          </div>

          <p className="footer-copyright">© Peer Digital Sweden AB</p>
        </div>

        {/* Column 2 - About */}
        <div className="footer-column">
          <Link to="/about" className="footer-link">Om Medla</Link>
          <Link to="/faq" className="footer-link">Vanliga frågor</Link>
          <Link to="/help" className="footer-link">Hjälp</Link>
        </div>

        {/* Column 3 - Jobs */}
        <div className="footer-column">
          <Link to="/sitemap" className="footer-link">Sitemap</Link>
          <Link to="/terms" className="footer-link">Användarvillkor</Link>
          <Link to="/privacy" className="footer-link">Sekretesspolicy</Link>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
